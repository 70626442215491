<template>
  &copy; 2021 RE Web Stride (www.rewebstride.com), All Rights Reserved.
</template>

<script>
    export default {
        name: "FooterMain"
    }
</script>

<style scoped>

</style>
