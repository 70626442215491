<template>
  <div class="left-box">
    <div class="menu-icon" @click="openedStateChange" v-if="isCollapse">
      <el-icon v-if="isCollapse"><Expand /></el-icon>
    </div>
  </div>
  <div style="width: 100%">
    <div class="header-search">
      <header-search />
    </div>
    <i
        class="far fa-question-circle"
        aria-hidden="true"
    />
    <i
        class="fas fa-cogs"
        aria-hidden="true"
    />
    <el-dropdown class="dropdown">
      <i
          class="fas fa-th-large"
          aria-hidden="true"
      />
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item>View</el-dropdown-item>
          <el-dropdown-item>Add</el-dropdown-item>
          <el-dropdown-item>Delete</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
    <router-link to="/logout">
      <i class="fas fa-sign-out-alt" />
    </router-link>
    <span v-if="userdata">Welcome {{userdata.name}}</span>
  </div>
</template>

<script>
import { useStore } from "vuex";
import HeaderSearch from "../HeaderSearch/"
    export default {
        name: "HeaderHome",
      components: {
          HeaderSearch
      },
      mounted() {
          this.restoreUserData()
      },
      computed: {
        isCollapse() {
          return this.store.state.app.isCollapse
        }
      },
      data() {
          return {
            store: useStore(),
            userdata: null
          }
      },
      methods: {
        restoreUserData() {
          let userdata = JSON.parse(localStorage.getItem('user'))
          if(userdata) {
            this.userdata = userdata
          }
        },
        openedStateChange(){
          this.store.commit('app/isCollapseChange', !this.isCollapse)
        },
      },

    }
</script>

<style scoped>
.far, .fas {
  margin-right: 20px;
  color: #000;
}
.header-search {
  width:40%;
  position: relative;
  float: left;
  margin-left: 25%;
  overflow: auto;
  line-height: 1.5;
  margin-top: 10px;
}
.dropdown{
  margin-top:22px;
}
.left-box {
  height: 100%;
  display: flex;
  align-items: center;
}
.left-box .menu-icon {
    width: 60px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 34px;
    font-weight: 100;
    cursor: pointer;
    margin-right: 10px;
    /*&:hover {*/
     /*background-color: var(--system-header-item-hover-color);*/
    /*}*/
    /*i {*/
    /*  color: var(--system-header-text-color);*/
    /*}*/
}
</style>
